<template>
	<div class="Yhteys">
			<div class="container-fluid">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-8">
					<img class="img-fluid fluid-grow" alt="" src="../assets/kuvaesite.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px;">
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h2>Ilmoittautuminen Urapurjeen Vinssi-pienryhmiin</h2>
				</div>
				<div class="col"></div>
			</div>

			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Urapurje järjestää työttömille työnhakijoille työllistymistavoitteista pienryhmätoimintaa. Ryhmät kokoontuvat Turussa Kupittaalla.</p>

					<p>Jos toimintamalli kiinnostaa, täytä allaoleva hakulomake. Yhteen ryhmään valitaan max. 10 osallistujaa. Ryhmä kokoontuu 9 viikon ajan, kerran viikossa 3 tuntia kerrallaan. Tapaamiset ovat viikon välein ja jokaisen tapaamisen välillä on viikkotehtäviä. Ryhmälle on luotu valmis ohjelmamalli, joka tarkentuu tarvittaessa osallistujien toiveiden mukaan. </p>

					<p>Toimintaa koordinoi ryhmäohjaaja, joka itse on vertainen ja osallistunut johonkin aiemmista ryhmistä. Otamme sinuun yhteyttä ja kerromme ryhmien tämän hetkisen tilanteen.</p>

					<p>Pienryhmätoiminta on Varsinais-Suomen TE-toimiston sekä AKAVAn ammattiliittojen rahoittamaa. </p>

					<a href="/download/Rekisteriseloste.pdf" type="button" class="btn btn-danger btn-t btn-danger-t" download>Rekisteriseloste</a>
					<p>Lisätietoja: info@urapurje.fi</p>

				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h2>Ilmoittaudu mukaan</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
				<div>					
					<!--Form/-->
					<a href="https://forms.gle/UwCfxMNnKNy4LZPN8 " type="button" class="btn btn-danger btn-t btn-danger-t">Ilmoittaudu mukaan!</a>
				</div>

				</div>
				<div class="col"></div>
			</div>		
		</div>
		<Social/>
	</div>
</template>

<script>
//import Form from '../components/Form.vue'
import Social from '../components/Social.vue'

export default {
name: 'Yhteys',
  components: {
    //Form,
    Social
  }
}
</script>

<style type="text/css">

</style>